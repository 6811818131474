import React from "react"
import resume from '../../content/bouweceunen.resume.pdf';
import SEO from '../components/seo/seo';
import "../styles/style.css"

const Resume = () => (
  <>
    <SEO title="Bouwe Ceunen Resume"/>
    <div className="flex min-h-screen justify-center items-center text-gray-900">
      <div className="py-12">
        <h2 className="text-3xl md:text-5xl text-center font-extrabold tracking-tight">
          <a href={resume} target='_blank' rel="noreferrer">Open Resume</a>
        </h2>
        <h2 className="text-3xl md:text-5xl text-center font-extrabold tracking-tight">
          <a href={resume} download='bouweceunen.resume.pdf'>Download Resume</a>
        </h2>
      </div>
    </div>
  </>
);

export default Resume
